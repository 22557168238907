import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { pxToRem } from '../../theme/typography'
import PointsComp from '../../components/ScoreBoard/PointsComp'

const RankingHeader = ({ isAdmin }) => {
    return (
        <Box sx={{ height: "40px", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", border: "1px solid #0BB2D0", background: "#0BB2D00D", width: "100%", borderRadius: "5px" }}>
            <Typography color={"primary"} sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>Ranking</Typography>
            {isAdmin && <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Name</Typography>}
            <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Points</Typography>
        </Box>
    )
}

const RankingRowApi = ({ adminView, points, isAdmin, i, j, user}) => {
    const [showPoint, setShowPoint] = useState(false)
    let role = i?.role == 'ableToScores' ? '(A) (S)' : i?.role == 'admin' ? '(A)' : '';
    return (
        <>
            <Box onClick={() => {
                setShowPoint(!showPoint)
            }}
                sx={{ height: "40px", cursor: "pointer", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", marginTop: "15px", background: (user == i?._id) ? "#98fb98" : "#0DB1D41A", width: "100%", borderRadius: "5px" }}>
                <Typography sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>{j + 1}</Typography>
                {<Typography sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>{(isAdmin || user == i?._id) ? (i?.firstName + " " + i?.lastName + " ") : " "+role}</Typography>}
                <Typography sx={{ width: "40%", fontSize: pxToRem(14), fontWeight: "600" }}>{i?.points}</Typography>
            </Box>
            {showPoint && points?.filter(x => i?._id == x?.user?._id)?.map((i, j) => {
                return <PointsComp i={i} j={j} style={{ marginTop: "15px" }} admin={isAdmin} key={i?._id} adminView={adminView} />
            })
            }
        </>
    )
}

export {
    RankingHeader,
    RankingRowApi
}