import React, { useEffect, useState } from 'react'
import moment from "moment"
import hit from "../../services/apiManager";
import showToast from "../../utils/showToast";
import TwoSelectionTab from "../../components/TwoSelectionTab";
import GroupScoreProfile from "../../components/ScoreBoard/GroupScoreProfile";
import ScoreButtons from './ScoreButtons';
import PointsComp from "../../components/ScoreBoard/PointsComp";
import { scores } from "../../services/endpoints";
import { useSelector } from "react-redux"
import { Box, Typography, Button } from '@mui/material';
import { RankingHeader, RankingRowApi } from './Ranking';
import { pxToRem } from "../../theme/typography";

const PointHistory = ({ isAdmin, selectedTeam }) => {

    const user = useSelector(state => state?.auth?.user);

    const [currentTab, setCurrentTab] = useState(0)
    const [selectedQuarter, setSelectedQuarter] = useState("all")
    const [ableToScore, setAbleToScore] = useState(false)
    const [adminView, setAdminView] = useState("Admin")
    const [sdate, setSdate] = useState('')
    const [edate, setEdate] = useState('')
    const [scoringMode, setScoringMode] = useState(null); //all daily weekly monthly miscellaneous
    // ranking
    const rankingPageSize = 50;
    const [rankings, setRankings] = useState([]);
    const [rankingPage, setRankingPage] = useState(1);
    const [totalRankPage, setTotalRankPage] = useState(0);
    // points
    const pointsPageSize = 50;
    const [scoreList, setScoreList] = useState([]);
    const [pointsPage, setPointsPage] = useState(1);
    const [totalPointsPage, setTotalPointsPage] = useState(0);

    const getRankings = async (group, page = 1) => {
        if (page == 1) { //incase  somehow 2 times fn called then delete prev one
            setRankings([]);
        }
        let query = `?groups=${group}&mode=${scoringMode}&page=${page}&pageSize=${rankingPageSize}`;
        if (scoringMode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(sdate).format('DD-MM-YYYY')}&endDate=${moment(edate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.ranking}${query}`);
        if (!res?.err) {
            let rankingData = res?.data?.ranking;
            rankingData = Array.isArray(rankingData) ? rankingData : [];
            let prevRanking = []
            if (page != 1) {
                prevRanking = [...rankings];
            }
            prevRanking = [...prevRanking, ...rankingData];
            setRankings(prevRanking);
            setRankingPage(page);
            setTotalRankPage(res?.data?.totalPages);
        } else {
            showToast(res?.msg || 'Something went wrong.');
        }
    }

    const setDates = (start, end) => {
        console.log("in parents", start, end)
        setSdate(start)
        setEdate(end)
    }

    const getPointsData = async (id, newpage = null) => {
        let query = `?groups=${id}&mode=${scoringMode}&page=${newpage || pointsPage}&pageSize=${pointsPageSize}`;
        if (adminView == "User" || !isAdmin) {
            query = query.concat(`&user=${user?._id}`)
        }
        if (scoringMode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(sdate).format('DD-MM-YYYY')}
                &endDate=${moment(edate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.getscore}/history${query}`);
        if (res?.err) {
            showToast(res?.msg)
        } else {
            if (newpage && newpage != 1) {
                setScoreList([...scoreList, ...res?.data?.list])
            } else {
                setScoreList(res?.data?.list)
            }
            setTotalPointsPage(res?.data?.totalPages ?? 1)
        }
    }

    useEffect(() => {
        const callAction = async () => {
            if (selectedTeam) {
                if (isAdmin) {
                    if (selectedTeam?.ableToScores.includes(user?._id)) {
                        setAbleToScore(true)
                    } else {
                        setAbleToScore(false)
                    }
                } else {
                    setAbleToScore(false)
                }
            }
            let oldScoringMode = scoringMode
            let newScoringMode = 'all'
            if (oldScoringMode == newScoringMode) {
                await getPointsData(selectedTeam?._id, 1)
                await getRankings(selectedTeam?._id, 1)
            } else {
                setScoringMode('all')
            }
        }
        callAction()
    }, [selectedTeam])

    useEffect(() => {
        const fetchScoring = async () => {
            if (scoringMode) {
                await getPointsData(selectedTeam?._id, 1)
                await getRankings(selectedTeam?._id, 1)
            }
        }
        fetchScoring();
    }, [scoringMode])

    useEffect(() => {
        setScoringMode(selectedQuarter?.toLowerCase())
    }, [selectedQuarter])

    useEffect(() => {
        const fetchScoring = async () => {
            if (scoringMode) {
                await getPointsData(selectedTeam?._id, 1)
                await getRankings(selectedTeam?._id, 1)
            }
        }
        fetchScoring();
    }, [adminView])

    return (
        <>
            {ableToScore &&
                <TwoSelectionTab
                    setCurrentTab={(v) => {
                        if (v == 0) {
                            setAdminView("Admin")
                        } else {
                            setAdminView("User")
                        }
                    }}
                    t1={"Admin"}
                    t2="User"
                    style={{ background: "white", marginTop: "0px", marginBottom: "25px" }}
                />
            }
            {
                [0].map((x) => {
                    let totalTemp = 0
                    return (
                        <GroupScoreProfile
                            isScore={true}
                            fin={isAdmin ? adminView == "User" ? selectedTeam?.myTotalPoints ?? 0 : selectedTeam?.avg_score ?? 0 : selectedTeam?.myTotalPoints ?? 0}
                            adminView={adminView}
                            totalTemp={totalTemp}
                            isAdmin={isAdmin}
                            avgPoint={selectedTeam?.avg_score ?? 0}
                            yourPoint={selectedTeam?.myTotalPoints ?? 0}
                            team={selectedTeam}
                        />
                    )
                })
            }
            <br />
            <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Groups</Typography>
            <br />
            <ScoreButtons
                key={selectedTeam?._id}
                groupId={selectedTeam?._id}
                isAdmin={isAdmin}
                adminView={adminView}
                setDates={setDates}
                setSelectedQuarter={setSelectedQuarter}
            />
            <br />
            <TwoSelectionTab
                setCurrentTab={setCurrentTab}
                t1={"Points"}
                t2="Ranking"
                style={{ background: "white" }}
            />
            <br />
            {currentTab == 0 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    {scoreList?.map((i, j) => {
                        return <PointsComp i={i} j={j} style={{ marginTop: j != 0 ? "15px" : "0px" }} admin={isAdmin} key={i?._id} adminView={adminView} />
                    })
                    }
                    {totalPointsPage > pointsPage && <Button onClick={() => {
                        setPointsPage(pointsPage + 1)
                        getPointsData(selectedTeam?._id, pointsPage + 1)
                    }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                        Load More
                    </Button>}
                </Box>
            }
            {currentTab == 1 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <RankingHeader isAdmin={isAdmin} />
                    {rankings && rankings.map((i, j) => {
                        return (
                            <RankingRowApi 
                                adminView={adminView} 
                                points={[]} 
                                isAdmin={isAdmin} 
                                i={i} 
                                j={j} 
                                key={i?._id} 
                                user={user?._id}>
                            </RankingRowApi>
                        )
                    })
                    }
                    {isAdmin &&
                        <Typography
                            sx={{ fontSize: pxToRem(14), marginTop: "10px", fontWeight: "500" }}>
                            Note:- A for Admin and S for Scoring enabled.
                        </Typography>}
                    {totalRankPage > rankingPage &&
                        <Button
                            onClick={() => { getRankings(selectedTeam?._id, rankingPage + 1) }}
                            sx={{ marginTop: "20px", color: "#58C0DC" }}
                            variant="outline">
                            Load More
                        </Button>}
                </Box>
            }
            <br />
        </>
    )
}

export default PointHistory